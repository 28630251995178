.partners{


    height: 800px ;
  
    background: linear-gradient( #1A1423 ,  #19a5f4 , #1A1423);

    /*background: linear-gradient( #1a1423 , #7507F4);*/
  
  }

  .part-text {

    font-size: 50px ;

    color: white;

    background: linear-gradient(to right, #19A5F4 , #7507F4);
    
    background-clip: text;
    -webkit-text-fill-color: transparent;



  }

  .part-text-2 {

    color : white ;
    font-size: 30px ;
  }
