.footer{


    height: 200px ;
  
    background: linear-gradient( to right , black , #19a5f4 );

    align-content: end;

    text-align: right;

    /*background: linear-gradient( #1a1423 , #7507F4);*/
  
  }
