.book {

    height: 100vh;

    background-color: grey;


    
    
}

.book-in {


    /*width: 100%;*/
    /*height: 100%;*/
    height: 100hv;
    color: white;

 
    background: rgb(127,112,204);
    background: linear-gradient(49deg, rgba(127,112,204,1) 0%, rgba(5,5,5,1) 16%, rgba(58,104,113,1) 89%, rgba(0,212,255,1) 100%);

    background-size: cover;
    background-position: center center;




}

.frame1 {

    color: white;

    font-size: 60px;


}

.text-top {

    color: white;
    font-size: 40px ;
}


.vl {
    border-left: 2px solid white;
    /*height: 500px;*/
  }