.climb {

    height: 800px ;
    background-color: #1A1423 ;
}

.cli-text-1 {

    font-size: 60px ;
    color: white;

    text-align: center ;

}

.cli-text-2 {

    font-size: 60px ;

    text-align: center ;


    background: linear-gradient(to right, #19A5F4 , #7507F4);
    
    background-clip: text;
    -webkit-text-fill-color: transparent;
}


.cli-text-3 {
    font-size: 30px;
    text-align: center;

    color: lightslategray;
}

.cli-text-4 {

    font-size: 30px;
    text-align: center;

    color: white;

}


.buttonInfo-1 {
    /*width: 250px ;*/
    /*height: 100;*/
    background: linear-gradient(to right, #19A5F4 , #7507F4);
    font-size: 30px; 

    border: none;

    border-radius: 2px;

    word-wrap: break-word;
    
    
    /*
    background-clip: text;
    -webkit-text-fill-color: transparent;
    */
  
  }

  .buttonInfo-2 {
    /*width: 250px ;*/
    /*height: 100;*/
    background: linear-gradient(to bottom, #FFFF , #1A1423);
    font-size: 20px; 

    border: none;

    border-radius: 2px;

    word-wrap: break-word;
    
    
    /*
    background-clip: text;
    -webkit-text-fill-color: transparent;
    */
  
  }

  .div-1 {

    height: 120 px;

    background: linear-gradient(to right, #19A5F4 , #1A1423);

    border: none;

    border-radius: 8px;

    word-wrap: break-word;

  }

  .div-2 {

    height: 120px ;

    background: linear-gradient(to right, #7507F4 , #1A1423);


    border: none;

    border-radius: 8px;

    word-wrap: break-word;


  }

  .div-3 {

    height: 30;

    border: none;

    border-radius: 8px;

    word-wrap: break-word;

  }

.div-text-1 {

    color: white;

    font-size: 40px;

    text-align: center ;


}
  
.div-text-2 {

    color: white;

    font-size: 25px;

    text-align: center ;


}
