.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

/*  
  align-items: center;

  justify-content: center;
*/  

  margin-top: 250px;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-text-gradient{

    /*background: -webkit-linear-gradient(#19A5F4, #7507F4);*/

    background: linear-gradient(to right, #19A5F4 , #7507F4);
    
    background-clip: text;
    -webkit-text-fill-color: transparent;
    

}

.App-back {
 height: 100vh;

  /*background: rgb(26,20,35);
  background: linear-gradient(180deg, rgba(26,20,35,1) 0%, rgba(26,20,35,1)89%, rgba(117,7,250,1) 100%);

  background: linear-gradient(to bottom, rgba(26,20,35,1) 0%, rgba(26,20,35,1)89%, rgba(117,7,250,1) 100%);

  linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)),
  */

  
  background-image:  url('images/background-portada.jpg');
  background-size: cover;
  background-position: center center;

/*  opacity: 0.6; */

 /* background-repeat: no-repeat; */

 


}

/*
#show_bg_2 {
  background-image:
  linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)),
  url('images/background.jpg');
  width: 80%;
  height: 400px;
  background-size: cover;
  color: white;
  padding: 20px;
}

*/


.Img-margin {

  padding-left: 40px ;
  padding-top:  40px ;


}

.App-logohh {



  display: flex;



}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.buttonInfo {
  width: 200px ;
  background: linear-gradient(to right, #19A5F4 , #7507F4);
  font-size: 20px; 
  /*
  background-clip: text;
  -webkit-text-fill-color: transparent;
  */

}

.buttonalign{
  align-items: center;
}

.posfix{
  position: fixed;
}


.whats { 
  height: 40px; 
  position: fixed; 
  bottom:0%;
  justify-content: right ;
 
  
  width:100%;
  /* 
  background-color: #393838; 
  opacity: 1;
  */
}



.language {
  align-items:right ;
}


