.optim {

    height: 100vh;

    background-color: #1A1423;

    background-image: url('images/fondo-optimizing.png');
    background-size: cover;
    background-position: center center;
  
    background-repeat: no-repeat;
  
  
  }

  .opsearch {

    font-size: 60px ;

    /*color: white;*/

    background: linear-gradient(to right, #19A5F4 , #7507F4);
    
    background-clip: text;
    -webkit-text-fill-color: transparent;



  }

  .optext {
    display: inline ;
    font-size: 30px ;
    color: white;
  }

  .inline {

    font-size: 30px ;
    color: white;


    display: inline
  }