

.about {

    height: 100vh;

    background: linear-gradient(45deg, #7507FA 10% , #1A1423 90% , #19A5F4  100%);



}

.frame1 {

    color: red;

    font-size: 60px;


}


.frame2 {

    border-left: 1px solid white;
}


.frame1-1 {


    color: white;
    font-size: 30px;
}

.frame1-2 {

    visibility: visible;
    color:white;
    font-size: 60px;

}


.vl {
    border-left: 2px solid white;
    height: 500px;
  }