.testimonials{


    height: 800px ;
  
    background: linear-gradient( #1A1423 ,  #19a5f4 , #1A1423);

    /*background: linear-gradient( #1a1423 , #7507F4);*/
  
  }

  .test-text {

    font-size: 60px ;
    color: white;

    text-align: center;

    background: linear-gradient(to right, #19A5F4 , #7507FA);
    
    background-clip: text;
    -webkit-text-fill-color: transparent;
    

  }

  .bloque {

    /*width: 100px;*/

    height: 300px;

    

    border-style: solid;
    border-width: 2px;
    border-color: white;

  }

  .text0 {

    color: white;

    font-size: 25 ;

  }